<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium"
              :class="`has-text-${iconType}`"
              style="margin-right: 0.5rem">
              <i class="mdi mdi-36px"
                :class="`${iconName}`" />
            </span>
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Item Type</label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
                <div class="control">
                  <div class="select">
                    <select v-model="selectedItemType">
                      <option v-for="(itemCategoryType, prop) in itemCategoryTypesFiltered"
                        :key="prop"
                        :value="prop">{{ itemCategoryType }}</option>
                      <!-- <option key="PAINM"
                        value="PAINM">Paint Material</option> -->
                    </select>
                  </div>
                </div>
                <button class="button"
                  @click="addPresentAs()"
                  :disabled="disableAdd"><i class="mdi mdi-plus-circle mdi-24px has-text-success" /></button>
              </div>
            </div>
          </div>
          <hr class="has-background-grey-light">
          <div v-if="innerValue"
            class="field">
            <div v-for="(split, index) in innerValue"
              :key="split.itemType"
              class="field has-addons">
              <p class="control">
                <a class="button is-static">
                  {{ split.itemType }}
                </a>
              </p>
              <p class="control">
                <vue-numeric class="input has-text-right"
                  v-model="split.percent"
                  :min="0"
                  symbol="%"
                  symbol-position="suffix"
                  v-focus-inserted />
              </p>
              <button class="button"
                @click="deletePresentAs(index)"><i class="mdi mdi-minus-circle mdi-24px has-text-danger" /></button>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="columns is-flex-grow-1"
            S>
            <div class="column is-half is-flex-grow-1">
              <div v-if="!validPercentage"
                class="is-flex is-flex is-align-items-center has-text-danger">
                <i class="mdi mdi-alert-circle mdi-24px pr-2" />
                <span>Total percentage is not 100%</span>
              </div>
            </div>
            <div class="column is-half is-flex"
              :class="`is-justify-content-${buttonLocation}`">
              <button class="button is-primary"
                @click="ok"
                :disabled="disableOk">Ok</button>
              <button class="button"
                @click="cancel">Cancel</button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>

<script>
import BaseModalEx from './BaseModalEx'
import { ItemCategoryTypes } from '@/enums'
import VueNumeric from '@/components/VueNumeric'
import _cloneDeep from 'lodash/cloneDeep'
import { FocusInserted } from '@/components/directives'
import DeepDiff from 'deep-diff'

export default {
  name: 'QuoteItemPresentAsModal',
  directives: {
    FocusInserted
  },
  components: {
    BaseModalEx,
    VueNumeric
  },
  mixins: [],
  props: {
    value: {
      type: Array,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    maximumOptions: {
      type: Number,
      default: 10
    }
  },
  data: () => {
    return {
      isActive: false,
      innerValue: null,
      itemCategoryTypes: null,
      selectedItemType: this?.item?.itemType
    }
  },
  computed: {
    disableAdd() {
      return !this.selectedItemType || this.innerValue.length >= this.maximumOptions
    },
    totalPercentage() {
      if (!Array.isArray(this.innerValue) || this.innerValue.length === 0) {
        return 0
      }
      const total = this.innerValue
        .map((i) => i.percent)
        .reduce((total, percent) => {
          return total + percent
        })
      return total
    },
    disableOk() {
      return this.totalPercentage !== 100 && !DeepDiff.diff(this.value, this.innerValue)
    },
    validPercentage() {
      if (!Array.isArray(this.innerValue) || this.innerValue.length === 0) {
        return true
      }
      return this.totalPercentage === 100
    },

    // TODO: this is a band aid fix, due to time contraints, we are hardcoding the item category types
    //       this should be refactored to use data from user settings and fetch from the server
    itemCategoryTypesFiltered() {
      return {
        RR: 'RR',
        REP: 'REP',
        PAINT: 'PAINT',
        MECH: 'MECH',
        PART: 'PART',
        MISC: 'MISC',
        SUBL: 'SUBL'
      }
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {},
  mounted() {
    this.innerValue = _cloneDeep(this.value)
    // this.selectedItemType = this.innerValue.itemType
    this.isActive = this.active || false
    this.resetItemCategoryTypes()
  },
  beforeDestroy() {},
  methods: {
    ok() {
      this.$emit('input', _cloneDeep(this.innerValue))
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('ok')
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
      this.$emit('cancel')
    },
    resetItemCategoryTypes() {
      this.itemCategoryTypes = _cloneDeep(ItemCategoryTypes)
      if (this.innerValue) {
        this.innerValue.forEach((p) => {
          delete this.itemCategoryTypes[p.itemType]
        })
      }
    },
    addPresentAs() {
      if (!Array.isArray(this.innerValue)) {
        this.innerValue = []
      }
      this.innerValue.splice(this.innerValue.length, 1, {
        itemType: this.selectedItemType,
        description: '',
        percent: Math.max(100 - this.totalPercentage, 0)
      })
      this.resetItemCategoryTypes()
      this.selectedItemType = ''
    },
    deletePresentAs(index) {
      this.innerValue.splice(index, 1)
      this.resetItemCategoryTypes()
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.modal-card-body {
  min-height: 15em;
}
.button.is-static {
  min-width: 4.5rem;
}
</style>
