<template>
  <base-modal-ex v-if="$userInfo && $company"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="true"
    @update:active="close">
    <div class="card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="text-title" />
        </p>
        <button class="delete"
          aria-label="close"
          @click="close" />
      </header>
      <div class="card-content">
        <div class="content is-size-7 release-notes-content">
          <template v-for="(release, index) in releases">
            <div :key="index">
              <span class="has-text-weight-bold is-underlined">
                {{ $filters.formatDateLocale(release.date, $user.info.locale) }} - {{ release.title }}</span>
              <ul class="pb-4">
                <li v-for="(note, noteIndex) in release.notes"
                  :key="noteIndex"><span v-html="formatNote(note)" /></li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </base-modal-ex>
</template>

<script>
import BaseModalEx from './BaseModalEx'
import Releases from '@/assets/json/release-notes.json'

export default {
  name: 'ReleaseNotesModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  computed: {
    releases() {
      return Releases
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    formatNote(note) {
      if (typeof(note) == 'string' && note.includes(':')) {
        const [key, value] = note.split(':')
        return `<b>${key}</b>: ${value}`
      }
      return note
    }
  }
}
</script>

<style lang="scss" scoped>
.release-notes-content {
  max-height: 40em;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
</style>
