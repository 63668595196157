<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium"
              :class="`has-text-${iconType}`"
              style="margin-right: 0.5rem">
              <i class="mdi mdi-36px"
                :class="`${iconName}`" />
            </span>
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <slot name="text-content" />
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button v-if="!licenceExpired && ($user.hasEdit($route.meta.id) || $user.hasAdd($route.meta.id))"
            class="button is-success tooltip"
            @click="saveContinue()"
            data-tooltip="Save &amp; continue">Save &amp; Continue</button>
          <button class="button is-warning tooltip"
            @click="skipSave()"
            data-tooltip="Continue without save"
            :disabled="skipDisabled">Skip</button>
          <button class="button tooltip is-tooltip-topright"
            @click="close()"
            data-tooltip="Don't continue">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>
import BaseModalEx from './BaseModalEx'
import { mapGetters } from 'vuex'

export default {
  name: 'UnsavedModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    skipDisabled: {
      type: Boolean,
      default: false
    },
    onSaveContinue: {
      type: Function,
      default: () => {}
    },
    onSkipSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  computed: {
    ...mapGetters(['licenceExpired'])
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    skipSave() {
      this.$emit('skipSave')
    },
    saveContinue() {
      this.$emit('saveContinue')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
