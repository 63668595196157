<template>
  <transition
    :name="transition"
    mode="in-out"
    appear
    :appear-active-class="enterClass"
    :enter-active-class="enterClass"
    :leave-active-class="leaveClass"
    @beforeEnter="beforeEnter"
    @afterLeave="afterLeave">
    <div
      :class="classes"
      v-if="show">
      <!-- <div class="modal-background" @click="deactive"></div> -->
      <div class="modal-background" />
      <div
        class="modal-card"
        :style="customStyle">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            @click="deactive" />
        </header>
        <section class="modal-card-body">
          <slot />
        </section>
        <footer
          class="modal-card-foot"
          style="justify-content: flex-end">
          <a
            class="button is-primary"
            @click="ok">{{ okText }}</a>
          <a
            class="button"
            @click="cancel">{{ cancelText }}</a>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseModal from './BaseModal'

export default {
  mixins: [BaseModal],

  props: {
    title: {
      type: String
    },
    okText: {
      type: String,
      default: 'Ok'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    minWidth: {
      type: String
    }
  },

  computed: {
    classes() {
      return ['modal', 'animated', 'is-active']
    },
    customStyle() {
      if (this.minWidth) {
        return {
          minWidth: this.minWidth
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    ok() {
      this.$emit('ok')
    },

    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
