// Save conflict modal box with animate.css
<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium"
              :class="`has-text-${iconType}`"
              style="margin-right: 0.5rem">
              <i class="mdi mdi-36px"
                :class="`${iconName}`" />
            </span>
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <slot name="text-content" />
        </section>
        <footer class="modal-card-foot">
          <div class="columns is-gapless"
            style="width: 100%">
            <div class="column">
              <button v-show="showReload"
                class="button is-warning tooltip"
                @click="reload()"
                data-tooltip="Reload data">Reload</button>
            </div>
            <div class="column"
              :style="`display: flex; justify-content: flex-${buttonLocation}`">
              <button v-show="showOverwrite"
                class="button is-primary tooltip"
                @click="overwrite()"
                data-tooltip="Overwrite & Save data">Overwrite &amp; Save</button>
              <button v-show="showMerge"
                class="button is-primary tooltip"
                @click="merge()"
                data-tooltip="Merge & Save data">Merge &amp; Save</button>
              <button class="button tooltip"
                @click="close()"
                data-tooltip="Don't continue">Cancel</button>
            </div>
          </div>
        </footer>
        <!-- <footer class="modal-card-foot"
          style="justify-content: space-between">
          <button v-show="showReload"
            class="button is-warning tooltip"
            @click="reload()"
            data-tooltip="Reload data">Reload</button>
          <div>
            <button class="button is-primary tooltip"
              @click="overwrite()"
              data-tooltip="Overwrite & Save data">Overwrite</button>
            <button class="button tooltip"
              @click="close()"
              data-tooltip="Don't continue">Cancel</button>
          </div>
        </footer> -->
      </div>
    </div>
  </base-modal-ex>
</template>
<script>
import BaseModalEx from './BaseModalEx'

export default {
  name: 'SaveConflictModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    showReload: {
      type: Boolean,
      default: true
    },
    showOverwrite: {
      type: Boolean,
      default: false
    },
    showMerge: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    overwrite() {
      this.$emit('overwrite')
    },
    merge() {
      this.$emit('merge')
    },
    reload() {
      this.$emit('reload')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
