<template>
  <base-modal-ex v-if="$userInfo && $company"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="true"
    @update:active="close()">
    <div class="card">
      <div class="card-image pt-4">
        <figure class="image">
          <img src="@/assets/images/flexiquote-logo.svg"
            style="max-height: 5rem">
        </figure>
      </div>
      <div class="is-divider mb-0" />
      <div class="card-content">
        <div class="content">
          Version: {{ latestVersion ? latestVersion.version : 'Not available' }}<br>
          Commit Hash: {{ commitHash }}<br>
          Build Date: {{ $filters.formatDateTimeLocale(timeStamp, $userInfo.locale) }}<br>
          Instance Name: {{ instanceName }}
          <div class="is-divider" />
          <span>Licence Expiry: {{ expiryDate }}</span>
          <div class="is-divider" />
          <div class="field is-grouped is-grouped-multiline ">
            <div class="control">
              <div class="tags has-addons">
                <span class="tag is-dark">nodejs</span>
                <span class="tag is-primary">{{ nodeVersion }}</span>
              </div>
            </div>
            <div class="control">
              <div class="tags has-addons">
                <span class="tag is-dark">npm</span>
                <span class="tag is-gold">{{ npmVersion }}</span>
              </div>
            </div>
            <div class="control">
              <div class="tags has-addons">
                <span class="tag is-dark">vuejs</span>
                <span class="tag is-success">{{ vueVersion }}</span>
              </div>
            </div>
            <div class="control">
              <div class="tags has-addons">
                <span class="tag is-dark">bulma</span>
                <span class="tag is-turquoise">{{ bulmaVersion }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <header class="card-header mb-1">
          <p class="card-header-title">
            Release Notes
          </p>
        </header>
        <div class="content is-size-7 release-notes-content">
          <template v-for="(release, index) in releases">
            <div :key="index">
              <span class="has-text-weight-bold is-underlined">{{ $filters.formatDateLocale(release.date, $user.info.locale) }} - {{ release.title }}</span>
              <ul class="pb-4">
                <li v-for="(note, noteIndex) in release.notes"
                  :key="noteIndex"><span v-html="note" /></li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </base-modal-ex>
</template>

<script>
import BaseModalEx from './BaseModalEx'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import FqApi from '@/services/fq-api'
// import ReleaseNotes from '@/release-notes.json'
import Releases from '@/assets/json/release-notes.json'

export default {
  name: 'About',
  components: {
    BaseModalEx
  },
  mixins: [DateTimeFiltersMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    expiryDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: this.active || false,
      version: '',
      commitHash: '',
      timeStamp: null,
      nodeVersion: '',
      npmVersion: '',
      vueVersion: '',
      bulmaVersion: '',
      instanceName: ''
    }
  },
  computed: {
    releases() {
      return Releases
    },
    latestVersion() {
      return Releases[0]
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.timeStamp = new Date(process.env.VUE_APP_BUILD_TIMESTAMP)
    this.version = process.env.VUE_APP_VERSION
    this.commitHash = process.env.VUE_APP_COMMIT_HASH
    this.nodeVersion = process.env.VUE_APP_NODE_VERSION
    this.npmVersion = process.env.VUE_APP_NPM_VERSION
    this.vueVersion = process.env.VUE_APP_VUE_VERSION
    this.bulmaVersion = process.env.VUE_APP_BULMA_VERSION
    this.getInstanceName()
    // console.log(
    //   `Version: ${process.env.VUE_APP_VERSION} Build: ${process.env.VUE_APP_COMMIT_HASH} (${this.timeStamp.toLocaleDateString('en-AU')} ${this.timeStamp.toLocaleTimeString()})`
    // )
  },
  mounted() {},
  methods: {
    async getInstanceName() {
      const url = 'settings/instance/name'
      const result = await FqApi.get(url)
      this.instanceName = result.data
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
div.media-content {
  overflow: hidden;
}
.is-divider {
  margin: 1.2em 0;
}

.release-notes-content {
  max-height: 20em;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
</style>
