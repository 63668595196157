<template>
  <base-modal-ex
    id="pdf-modal"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="true"
    :min-width="90"
    :min-height="90"
    @update:active="close()">
    <div class="box">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <h4
              v-if="title"
              class="title is-5">{{ title }}</h4>
            <div :style="`overflow: none;`">
              <object
                id="pdfDoc"
                type="application/pdf"
                :data="pdfBase64"
                :style="pdfDisplayStyle" />
            </div>
          </div>
        </div>
      </article>
    </div>
  </base-modal-ex>
</template>

<script>
import BaseModalEx from './BaseModalEx'

export default {
  name: 'PdfModal',
  components: {
    BaseModalEx
  },
  props: {
    pdf: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    }
  },
  data() {
    return {
      isActive: this.active || false,
      pdfBase64: 'data:application/pdf;base64,' + this.pdf,
      pdfDisplayStyle: this.title !== '' ? 'width: 100%; min-height: 80vh;' : 'width: 100%; height: 85vh;'
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    // this.pdfBase64 = 'data:application/pdf;base64,' + this.pdf
    // const vm = this
    // setTimeout(function() {
    //   vm.pdfBase64 = 'data:application/pdf;base64,' + vm.pdf
    // }, 0)
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
