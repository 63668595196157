import { render, staticRenderFns } from "./UnsavedModal.vue?vue&type=template&id=110d9768&scoped=true"
import script from "./UnsavedModal.vue?vue&type=script&lang=js"
export * from "./UnsavedModal.vue?vue&type=script&lang=js"
import style0 from "./UnsavedModal.vue?vue&type=style&index=0&id=110d9768&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110d9768",
  null
  
)

export default component.exports